.form{
    width:60%;
    display: flex;
    flex-flow: column nowrap;
}
.form>label{
    margin-bottom: 16px;
    color: #DAD9D8;
}
label{
    color: #DAD9D8;
}
.inputBox{
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 28px;
}

.inputBox>input{
    background-color: #DAD9D8;
    width: 100%;
    height: 100%;
    border: 1px solid #DAD9D8;
    border-radius: 8px;
    color: #2F303A;
    text-align: center;
}
.inputBox>input::placeholder{
    color: #2F303A;
    /* padding-left: 00px; */
}
.inputBox>input:focus{
    color:  #2F303A;;
    border: 1px  #2F303A;
    outline: none;
}

.inputBox>img{
    position: absolute;
    top: 4px;
    left: 16px;
    height: 80%;
}

input[type=submit] {
    background-color: #F6871F;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    color: #2F303A;
    cursor: pointer;
}

.iconMail{
    top: 8px !important;
    height: 60% !important;
}

.btnBox{
    position: relative;
    width: 100%;
    height: 40px;
}
.btnBox>button{
    background-color: #F6871F;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    color: #2F303A;
    font-weight: 600;
}

.btnBox>img{
    position: absolute;
    top: 4px;
    left: 16px;
    height: 80% !important;
}

.wrapperChecks {
    width: 100%;
    height: 60px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
.contentCheck{
    width: 28px;
    height: 28px;
    background-color: transparent;
    margin-right: 16px;
    border: 2px solid white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.customCheck{
    width: 20px;
    height: 20px;
    background-color: greenyellow;
    border-radius: 4px;
}
.errorTxt{
    color: red;
    margin-bottom: 16px;
}
.sendTxt{
    color: greenyellow;
    margin-bottom: 16px;
}

@media(max-width: 540px){
    .form{
        width:90%;
    }
}
