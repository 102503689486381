@font-face {
  font-family: "Barlow-Black";
  src: url("./assets/fonts/Barlow-Black.ttf");
  font-style: "Black";
}

@font-face {
  font-family: "Barlow-Bold";
  src: url("./assets/fonts/Barlow-Bold.ttf");
  font-style: "Bold";
}

@font-face {
  font-family: "Barlow-Medium";
  src: url("./assets/fonts/Barlow-Medium.ttf");
  font-style: "Medium";
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-decoration: none;
  
}

:root{
  --orange: #F6871F;
  --orangeLight: #F8A04E;
  --orangeDark: #D86D09;
  --dark: #17171C;
  --darkGray: #2F303A;
  --darkLight: #454654;
}
body{
  background-color: #2F303A ;
}

/* importar y asignar la fuente deseada */
/* Tamaño de la fuente min 12px a 320 - max 24 px a 1920 */
h1{
  font-family: "Barlow-Black";
  color: white;
  font-size: calc(2.5rem + ((1vw - 19.2px) * 0.375));
}
h2{
  font-family: "Barlow-Bold";
  color: white;
  font-size: calc(2rem + ((1vw - 19.2px) * 0.375));
}
h1 span{
  color: var(--orangeDark);
}
p, a{
  font-family: "Barlow-Medium";
  font-size: calc(1.3rem + ((1vw - 19.2px) * 0.375));
  line-height: 30px;
  color: #fff;
  line-height: calc(2rem + ((1vw - 19.2px) * 0.375)) ;
}
a{
  width: 100%;
  text-align: center;
}
p span{
  font-family: "Barlow-Bold";
  color: var(--orange);
}
input{
  /* font-family: "FuturaMedium"; */
  font-size: calc(1.125rem + ((1vw - 19.2px) * 0.375));
  line-height: 20px;
  border: 2px solid var(--green);
  height: 28px;
  border-radius: 8px;
  outline: none
}
button{/*fuente min 12px a 320 - max 24 px a 1920 */
  padding: 12px 14px;
  border-radius: 8px;
  /* font-family: "FuturaBlack"; */
  font-size: calc(1.125rem + ((1vw - 19.2px) * 0.375));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: #fff;
}
button:active{
  transform: translateY(1px);
}

.App{
  width: 100%;
  position: relative;
}

.backdrop{
  background: linear-gradient(152.97deg, rgba(21, 21, 21, 0.2) 0%, rgba(21, 21, 21, 0) 100%);
  backdrop-filter: blur(42px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index:0
}

::-webkit-scrollbar{
  background-color: var(--dark);
  width: 10px;
}

::-webkit-scrollbar-thumb{
  background-color: var(--darkLight);
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover{
  background-color: var(--darkGray);
}


@media (max-width: 320px) {
  p {
    font-size: 12px;
  }
  h4{ 
    font-size: 18px;
  }
}
